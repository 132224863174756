/* eslint-disable react/require-default-props */
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from 'react';

import { CompanyEligibilityData } from '../../../../../api/theHive/workOrders/types';
import MarkerSelected from '../../../../../assets/mapMarkers/selected.svg';
import StandardMarker from '../../../../../assets/mapMarkers/standard.svg';
import UnusedElement from '../../../../../assets/mapMarkers/unused.svg';

interface CustomMarkerProps {
  markerData: CompanyEligibilityData;
  selectedCompany: CompanyEligibilityData | undefined;
  setSelectedCompany: (data: CompanyEligibilityData) => void;
}

export default function CustomMarker({
  markerData,
  selectedCompany,
  setSelectedCompany,
}: CustomMarkerProps): JSX.Element {
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [isSelectedMarker, setIsSelectedMarker] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const getIconUrl = (isSelected: boolean): string => {
    if (isSelected) {
      return MarkerSelected;
    }
    if (markerData.is_absent_in_period || !markerData.is_active) {
      return UnusedElement;
    }
    return StandardMarker;
  };

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  useEffect(() => {
    if (
      selectedCompany?.licensed_company_id === markerData.licensed_company_id
    ) {
      setIsSelectedMarker(true);
      return;
    }
    if (markerData.is_absent_in_period || !markerData.is_active) {
      setIsSelectedMarker(false);
    } else {
      setIsSelectedMarker(false);
    }
  }, [markerData, selectedCompany]);

  return (
    <>
      <AdvancedMarker
        position={{
          lat: Number(markerData.latitude),
          lng: Number(markerData.longitude),
        }}
        ref={markerRef}
        zIndex={100}
        onClick={() => {
          setInfoWindowShown(true);
          setSelectedCompany(markerData);
        }}
        style={{
          width: '24px',
          height: '30px',
          clipPath:
            'polygon(25% 0%, 75% 0%, 99% 5%, 100% 50%, 75% 80%, 50% 100%, 25% 80%, 0% 50%, 1% 10%)',
        }}
      >
        <img
          onMouseOver={() => setInfoWindowShown(true)}
          onFocus={() => setInfoWindowShown(true)}
          onBlur={() => setInfoWindowShown(false)}
          onMouseOut={() => setInfoWindowShown(false)}
          src={getIconUrl(isSelectedMarker)}
          width={24}
          height={30}
          alt="marcador"
        />
      </AdvancedMarker>
      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose}>
          <div>
            <div
              style={{
                font: '500 18px Roboto',
                margin: '2px 6px',
              }}
            >
              {markerData.name}
            </div>
            {!markerData.is_active && (
              <div style={{ font: '500 14px Roboto', margin: '0 6px 6px' }}>
                inativa
              </div>
            )}
            {markerData.is_active && markerData.is_absent_in_period && (
              <div style={{ font: '500 14px Roboto', margin: '0 6px 6px' }}>
                ausente
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  );
}
