import { BaseUrl, myHeadersWithTokenContentType } from '../global';
import {
  DetailProps,
  EditUserProps,
  GetAllUsersProps,
  GetUserProps,
  UserProps,
} from './types';

export const createUser = (data: UserProps): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/users`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const editUser = (
  id: number,
  data: EditUserProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/users/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllUsers = (
  page: number,
  size: number
): Promise<GetAllUsersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/users?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getUser = (
  inspectorId: number,
  workCompanyId?: number
): Promise<GetUserProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  let url = `${BaseUrl}/api/V1/users/${inspectorId}?`;

  const paramsUrl = [];

  if (workCompanyId) paramsUrl.push(`worker_company_id=${workCompanyId}`);

  url += paramsUrl.join('');

  return fetch(`${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
