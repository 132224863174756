/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  convertStatus,
  convertStatusUrl,
  Status,
  StatusCode,
  WebsocketTypes,
} from '../../api/enumerations';
import { getAllWorkOrders, getTopWorkOrders } from '../../api/workOrders';
import {
  GetAllWorkOrderDataProps,
  GetTopWorkOrdersData,
  GetWorkOrdersParams,
  WorkOrderData,
} from '../../api/workOrders/types';
import { Search } from '../../components/Search';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/home';
import {
  IconAddMS,
  IconAssignmentMS,
  IconCheckCircleMS,
  IconCloseMS,
  IconDesktopWindowsMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { WebsocketContext } from '../../context/websocketMessage';
import useErrorMessage from '../../hooks/useErrorMessage';
import { BankSearch } from './BankSearch';
import { Column } from './Column';
import {
  GridContainer,
  OSButton,
  PipelineContainer,
  ScrollTypography,
  StyledToast,
  TitleBox,
  TitleTypography,
  ToastTypography,
  TVMode,
} from './styles';

export interface IWebsocketMessageData {
  osId: number;
  unreadCount: number;
}

export function Home(): JSX.Element {
  const [pipelineData, setPipelineData] = useState<
    GetAllWorkOrderDataProps | GetTopWorkOrdersData
  >();
  const [searchField, setSearchField] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState<GetWorkOrdersParams>();

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const pipelineColumn = [
    {
      id: 'entrance',
      name: convertStatus(Status.ENTRANCE),
      workOrders:
        pipelineData?.[Status.ENTRANCE] &&
        'items' in pipelineData[Status.ENTRANCE]
          ? pipelineData?.[Status.ENTRANCE]?.items
          : (pipelineData?.[Status.ENTRANCE] as WorkOrderData[]),
      link: convertStatusUrl(Status.ENTRANCE),
      status: Status.ENTRANCE,
      totalRecords:
        pipelineData?.[Status.ENTRANCE] &&
        'items' in pipelineData[Status.ENTRANCE]
          ? pipelineData?.[Status.ENTRANCE]?.total_records
          : undefined,
    },
    {
      id: 'pept',
      name: convertStatus(Status.PEPT),
      workOrders:
        pipelineData?.[Status.PEPT] && 'items' in pipelineData[Status.PEPT]
          ? pipelineData?.[Status.PEPT]?.items
          : (pipelineData?.[Status.PEPT] as WorkOrderData[]),
      link: convertStatusUrl(Status.PEPT),
      status: Status.PEPT,
      totalRecords:
        pipelineData?.[Status.PEPT] && 'items' in pipelineData[Status.PEPT]
          ? pipelineData?.[Status.PEPT]?.total_records
          : undefined,
    },
    {
      id: 'schedule',
      name: convertStatus(Status.SCHEDULE),
      workOrders:
        pipelineData?.[Status.SCHEDULE] &&
        'items' in pipelineData[Status.SCHEDULE]
          ? pipelineData?.[Status.SCHEDULE]?.items
          : (pipelineData?.[Status.SCHEDULE] as WorkOrderData[]),
      link: convertStatusUrl(Status.SCHEDULE),
      status: Status.SCHEDULE,
      totalRecords:
        pipelineData?.[Status.SCHEDULE] &&
        'items' in pipelineData[Status.SCHEDULE]
          ? pipelineData?.[Status.SCHEDULE]?.total_records
          : undefined,
    },
    {
      id: 'inspection',
      name: convertStatus(Status.INSPECTION),
      workOrders:
        pipelineData?.[Status.INSPECTION] &&
        'items' in pipelineData[Status.INSPECTION]
          ? pipelineData?.[Status.INSPECTION]?.items
          : (pipelineData?.[Status.INSPECTION] as WorkOrderData[]),
      link: convertStatusUrl(Status.INSPECTION),
      status: Status.INSPECTION,
      totalRecords:
        pipelineData?.[Status.INSPECTION] &&
        'items' in pipelineData[Status.INSPECTION]
          ? pipelineData?.[Status.INSPECTION]?.total_records
          : undefined,
    },
    {
      id: 'sample-creation',
      name: convertStatus(Status.SAMPLE_CREATION),
      workOrders:
        pipelineData?.[Status.SAMPLE_CREATION] &&
        'items' in pipelineData[Status.SAMPLE_CREATION]
          ? pipelineData?.[Status.SAMPLE_CREATION]?.items
          : (pipelineData?.[Status.SAMPLE_CREATION] as WorkOrderData[]),
      link: convertStatusUrl(Status.SAMPLE_CREATION),
      status: Status.SAMPLE_CREATION,
      totalRecords:
        pipelineData?.[Status.SAMPLE_CREATION] &&
        'items' in pipelineData[Status.SAMPLE_CREATION]
          ? pipelineData?.[Status.SAMPLE_CREATION]?.total_records
          : undefined,
    },
    {
      id: 'calculation',
      name: convertStatus(Status.CALCULATION),
      workOrders:
        pipelineData?.[Status.CALCULATION] &&
        'items' in pipelineData[Status.CALCULATION]
          ? pipelineData?.[Status.CALCULATION]?.items
          : (pipelineData?.[Status.CALCULATION] as WorkOrderData[]),
      link: convertStatusUrl(Status.CALCULATION),
      status: Status.CALCULATION,
      totalRecords:
        pipelineData?.[Status.CALCULATION] &&
        'items' in pipelineData[Status.CALCULATION]
          ? pipelineData?.[Status.CALCULATION]?.total_records
          : undefined,
    },
    {
      id: 'report',
      name: convertStatus(Status.REPORT),
      workOrders:
        pipelineData?.[Status.REPORT] && 'items' in pipelineData[Status.REPORT]
          ? pipelineData?.[Status.REPORT]?.items
          : (pipelineData?.[Status.REPORT] as WorkOrderData[]),
      link: convertStatusUrl(Status.REPORT),
      status: Status.REPORT,
      totalRecords:
        pipelineData?.[Status.REPORT] && 'items' in pipelineData[Status.REPORT]
          ? pipelineData?.[Status.REPORT]?.total_records
          : undefined,
    },
    {
      id: 'revision',
      name: convertStatus(Status.REVISION),
      workOrders:
        pipelineData?.[Status.REVISION] &&
        'items' in pipelineData[Status.REVISION]
          ? pipelineData?.[Status.REVISION]?.items
          : (pipelineData?.[Status.REVISION] as WorkOrderData[]),
      link: convertStatusUrl(Status.REVISION),
      status: Status.REVISION,
      totalRecords:
        pipelineData?.[Status.REVISION] &&
        'items' in pipelineData[Status.REVISION]
          ? pipelineData?.[Status.REVISION]?.total_records
          : undefined,
    },
  ];

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { websocketMessage } = useContext(WebsocketContext);
  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();

  const getTopWorkOrdersCallback = useCallback(async () => {
    if (!firstLoad) {
      return;
    }

    setLoading(true);

    try {
      const topWorkOrders = await getTopWorkOrders();

      if (topWorkOrders.detail.description) {
        throw new Error(topWorkOrders.detail.description);
      }

      if (topWorkOrders.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (topWorkOrders.data) {
        setPipelineData(topWorkOrders.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    getTopWorkOrdersCallback();
  }, [getTopWorkOrdersCallback]);

  useEffect(() => {
    if (websocketMessage) {
      const messageData = JSON.parse(websocketMessage.data);
      if (messageData.type === WebsocketTypes.AVM_PROCESSED) {
        setToastMessage(
          `OS ${messageData.payload.reference_number} finalizou o cálculo.`
        );
        setOpenToast(true);
        getTopWorkOrdersCallback();
      }
    }
  }, [websocketMessage]);

  const getWorkOrdersByFilters = useCallback(async () => {
    setLoading(true);

    const params: GetWorkOrdersParams = {};

    if (searchField.length > 0) params.search = searchField;
    if (filters?.uf) params.uf = filters?.uf;
    if (filters?.city) params.city = filters?.city;
    if (filters?.licenseCompanyId)
      params.licenseCompanyId = filters?.licenseCompanyId;
    if (filters?.startDate) params.startDate = filters?.startDate;
    if (filters?.endDate) params.endDate = filters?.endDate;

    try {
      const response = await getAllWorkOrders(params);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setPipelineData(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, [searchField, filters]);

  useEffect(() => {
    if (searchField.length > 0 || filters) {
      const delayDebounceFn = setTimeout(() => {
        getWorkOrdersByFilters();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    return setFirstLoad(true);
  }, [getWorkOrdersByFilters]);

  const handleNewOs = (): void => {
    navigate('/home/property/new');
  };

  const switchToPresentationMode = (): void => {
    navigate('/home/presentation-mode');
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      {IconCloseMS}
    </IconButton>
  );

  return (
    <GridContainer>
      <TitleBox>
        <TitleTypography>
          {IconAssignmentMS}
          {isBank ? Constants.bankTitle : Constants.title}
        </TitleTypography>
        <OSButton onClick={handleNewOs}>
          {IconAddMS}
          {Constants.buttonAdd}
        </OSButton>
        {!isBank && (
          <TVMode onClick={switchToPresentationMode}>
            {IconDesktopWindowsMS}
            {Constants.TVMode}
          </TVMode>
        )}
        {isBank ? (
          <BankSearch
            setSearchField={setSearchField}
            setFilters={setFilters}
            searchLoading={loading}
            isFiltersActive={!!filters}
          />
        ) : (
          <Search setSearchField={setSearchField} />
        )}
      </TitleBox>
      <PipelineContainer>
        {pipelineColumn.map((column) => (
          <Column
            key={column.name}
            columnData={column}
            pipelineLoading={loading}
            isSearching={searchField.length > 0}
          />
        ))}
      </PipelineContainer>
      <ScrollTypography>-</ScrollTypography>
      {openSnackbar && <Snackbar />}
      <StyledToast
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
        message={
          <ToastTypography>
            {IconCheckCircleMS}
            {toastMessage}
          </ToastTypography>
        }
      />
    </GridContainer>
  );
}
