import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxContainer = styled(Box)(({ theme }) => ({
  '.customMap': {
    height: 'clamp(660px, 37.8vw, 725px)',
    width: '100%',
  },
  '.customMap > div:first-of-type': {
    border: '1px solid #D7D8DB',
    borderRadius: '10px',
  },
  '.yNHHyP-marker-view': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
  [theme.breakpoints.down('xxl')]: {
    '.customMap': {
      height: 'clamp(500px, 36.8vw, 580px)',
      width: '100%',
    },
  },
}));

export const LoadingBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1000,
}));
