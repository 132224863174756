/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef } from 'react';

import { CompanyEligibilityData } from '../../../../api/theHive/workOrders/types';
import OSMarker from '../../../../assets/mapMarkers/os.svg';
import CustomMarker from './CustomMarker';

interface CustomMapProps {
  markersData: CompanyEligibilityData[];
  selectedCompany: CompanyEligibilityData | undefined;
  setSelectedCompany: (data: CompanyEligibilityData) => void;
  initialPinPlace: google.maps.LatLngLiteral;
  setMapBounds: (value: google.maps.LatLngBounds | undefined) => void;
  pinPlace?: google.maps.LatLngLiteral;
}

export default function CustomMap({
  markersData,
  selectedCompany,
  setSelectedCompany,
  initialPinPlace,
  setMapBounds,
  pinPlace,
}: CustomMapProps): JSX.Element {
  const map = useMap();
  const center = initialPinPlace;

  const prevPinPlaceRef = useRef<CustomMapProps['pinPlace'] | null>(null);
  const prevZoomRef = useRef<number | null>(null);

  useEffect(() => {
    if (!pinPlace || !map) return;

    if (
      !prevPinPlaceRef.current ||
      pinPlace.lat !== prevPinPlaceRef.current.lat ||
      pinPlace.lng !== prevPinPlaceRef.current.lng
    ) {
      map.panTo(pinPlace);
    }

    prevPinPlaceRef.current = pinPlace;
  }, [pinPlace, map]);

  useEffect(() => {
    if (!map) return undefined;

    const handleZoomChange = (): void => {
      const currentZoom = map.getZoom();
      if (currentZoom) {
        if (prevZoomRef.current === null || currentZoom < prevZoomRef.current) {
          if (currentZoom <= 9) {
            const bounds = map.getBounds();
            if (bounds) setMapBounds(bounds);
          } else {
            setMapBounds(undefined);
          }
        }
        prevZoomRef.current = currentZoom;
      }
    };

    const listener = map.addListener('zoom_changed', handleZoomChange);

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map]);

  return (
    <Map
      mapId="93ba1580047addb8"
      defaultCenter={center}
      streetViewControl={false}
      className="customMap"
      defaultZoom={14}
      minZoom={6}
    >
      <AdvancedMarker position={pinPlace} zIndex={10}>
        <img src={OSMarker} width={32} height={40} alt="marcador" />
      </AdvancedMarker>
      {markersData.map((marker) => (
        <CustomMarker
          key={marker.licensed_company_id}
          markerData={marker}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      ))}
    </Map>
  );
}
