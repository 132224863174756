/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import {
  CoordinatesData,
  DetailProps,
  GetHiveWorkOrdersResponse,
  GetWorkOrderEligibilityResponse,
  HiveRequestProps,
  HiveRequestStatusProps,
  SendMessageData,
  UnreadMessagesCountProps,
  WorkOrderMessagesProps,
} from './types';

export const getHiveWorkOrders = (
  search: string,
  page: number,
  size: number
): Promise<GetHiveWorkOrdersResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/hive?search=${search}&page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getWorkOrderEligibility = (
  id: number,
  data?: CoordinatesData | null
): Promise<GetWorkOrderEligibilityResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  const queryParams = new URLSearchParams();

  if (data) {
    queryParams.append('low_latitude', data.low_latitude);
    queryParams.append('low_longitude', data.low_longitude);
    queryParams.append('high_latitude', data.high_latitude);
    queryParams.append('high_longitude', data.high_longitude);
  }

  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/hive-eligibility?${queryParams.toString()}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getWorkOrderMessages = (
  osId: number,
  page: number,
  size: number
): Promise<WorkOrderMessagesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages?page=${page}&size=${size}&sort=-created_at`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendWorkOrderMessage = (
  osId: number,
  data: SendMessageData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getUnreadMessagesCount = (
  osId: number
): Promise<UnreadMessagesCountProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages/unread-count?sent_by=worker`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateMessagesAsRead = (osId: number): Promise<DetailProps> => {
  const data = {
    sender: 'worker',
  };
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages/read`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getMessagesUnread = (): Promise<any> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/hive-messages/unread-count?sent_by=worker`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateHiveRequestStatus = (
  osId: number,
  data: HiveRequestStatusProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-requests/work-status`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const checkHiveRequest = (osId: number): Promise<HiveRequestProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-requests`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
