/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { EvaluationType } from '../../api/enumerations';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { MessagesDialog } from '../../components/Messages';
import { ReportErrorDialog } from '../../components/ReportErrorDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PdfViewer } from '../../components/Sections/PdfViewer';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../components/Sections/ReportInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { StatisticalReport } from '../../components/Sections/StatisticalReport';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconDescriptionMS,
  IconDownloadMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants } from '../../constants/revision';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { ObservationDialog } from './ObservationDialog';
import {
  BackButton,
  DialogButton,
  DownloadButton,
  SectionBox,
  SubmitBox,
} from './styles';
import { useRevision } from './useRevision';

export function Revision(): JSX.Element {
  const [openErrorReport, setOpenErrorReport] = useState(false);

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { openSnackbar } = useContext(GlobalContext);

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { handleStatus, loadingApprove } = useChangeStatus();
  const { osId, navigateHome } = useGeneral();
  const {
    propertyData,
    loadingPage,
    rooms,
    sampleData,
    hasAvmReport,
    selectedPictures,
    handleUpdateStatus,
    workerFinalized,
    loadingAccepted,
    loadingRejected,
    hiveAccepted,
  } = useRevision();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine &&
      expandTen
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
    expandTen,
  ]);

  useEffect(() => {
    setExpandTen(true);
  }, [propertyData]);

  const downloadFile = (link: string | null): void => {
    if (link) {
      const linkSource = link;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.click();
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.revision}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <Box display="flex" gap={1.2}>
                {isBank && propertyData?.worker_company_id !== null && (
                  <MessagesDialog
                    osId={osId}
                    hiveRequest={propertyData?.hive_request || undefined}
                  />
                )}
                <AllAttachments propertyData={propertyData} osId={osId} />
              </Box>{' '}
            </FlexSpaceBetweenBox>
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            rooms={rooms}
                            workerCompanyId={
                              propertyData.worker_company_id || undefined
                            }
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.report}
                  icon={IconEngineeringMS}
                  openAccordion={expandEight}
                  setOpenAccordion={setExpandEight}
                />
                {expandEight && (
                  <Box>
                    {hasAvmReport && propertyData ? (
                      <ReportInfo propertyData={propertyData} />
                    ) : (
                      <Box>{Constants.noReport}</Box>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox id="statistical-report">
                <AccordionTitle
                  title="Relatório estatístico"
                  icon={IconDescriptionMS}
                  openAccordion={expandNine}
                  setOpenAccordion={setExpandNine}
                />
                {expandNine && propertyData && (
                  <StatisticalReport
                    propertyData={propertyData}
                    rooms={rooms}
                    selectedPictures={selectedPictures}
                    readOnly
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.pdfReport}
                  icon={IconDescriptionMS}
                  openAccordion={expandTen}
                  setOpenAccordion={setExpandTen}
                />
                {expandTen &&
                  (propertyData?.avm_status === null ? (
                    <Box display="flex" gap={2}>
                      <DownloadButton
                        disabled={
                          !propertyData.manual_statistical_report_report_pdf
                        }
                        onClick={() =>
                          downloadFile(
                            propertyData.manual_statistical_report_report_pdf
                          )
                        }
                      >
                        {IconDownloadMS} Relatório PDF
                      </DownloadButton>
                      <DownloadButton
                        disabled={
                          !propertyData.manual_statistical_report_calculation_report_document
                        }
                        onClick={() =>
                          downloadFile(
                            propertyData.manual_statistical_report_calculation_report_document
                          )
                        }
                      >
                        {IconDownloadMS} Memorial de cálculo
                      </DownloadButton>
                    </Box>
                  ) : (
                    <PdfViewer osId={osId} />
                  ))}
              </SectionBox>
              {!isBank && (
                <SubmitBox>
                  <DialogButton onClick={() => setOpenErrorReport(true)}>
                    {Constants.errorReport}
                  </DialogButton>
                  <ReportErrorDialog
                    open={openErrorReport}
                    setOpen={setOpenErrorReport}
                  />
                  <ConfirmationDialog
                    title={Constants.approve}
                    loading={loadingApprove}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    modalCallback={() =>
                      handleStatus(osId, propertyData?.reference_number)
                    }
                  />
                </SubmitBox>
              )}
              <SubmitBox>
                {workerFinalized && (
                  <Box display="flex" gap={2}>
                    <ObservationDialog
                      modalCallback={handleUpdateStatus}
                      loading={loadingRejected}
                    />
                    <ConfirmationDialog
                      title="Aprovar finalização"
                      loading={loadingAccepted}
                      text="Aprovar a finalização da OS"
                      modalCallback={handleUpdateStatus}
                    />
                  </Box>
                )}
                {hiveAccepted && (
                  <ConfirmationDialog
                    title="Arquivar"
                    text="arquivar esta OS"
                    loading={loadingApprove}
                    modalCallback={() =>
                      handleStatus(osId, propertyData?.reference_number)
                    }
                  />
                )}
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
