import {
  Box,
  BoxProps,
  Button,
  ButtonBase,
  CardMedia,
  Grid,
  IconButton,
  InputBase,
  Popover,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  width: '100%',
  minHeight: '420px',
  background: '#FFFFFE',
  boxShadow: '0px 3px 6px #00000029',
  margin: '48px 0 0',
  padding: '20px',
  [theme.breakpoints.up('xxl')]: {
    minHeight: '600px',
  },
}));

export const StyledInputBase = styled(InputBase)(() => ({
  color: 'inherit',
  width: '100%',
  borderBottom: '1px solid',
  borderImage:
    'linear-gradient(to right, transparent 8%, #004D43 8%, #004D43 75%) 5',
  padding: '0 8px',
}));

export const StyledDialog = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '4px',
    backgroundColor: '#FAFCFF',
    height: '560px',
    padding: '20px',
    margin: '16px 0',
    width: '400px',
    [theme.breakpoints.up('xl')]: {
      width: '500px',
    },
  },
}));

export const SkeletonStyled = styled(Skeleton)(() => ({
  height: 190,
  width: '100%',
  margin: '10px 0',
  background: '#fafcff',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
}));

interface CardStyledProps extends BoxProps {
  isAbsent?: boolean;
}

export const CardStyled = styled(Box)<CardStyledProps>(
  ({ theme, isAbsent }) => ({
    width: '100%',
    background: '#fafcff',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 10,
    margin: '10px 0',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    cursor: 'pointer',
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    ...(!isAbsent && {
      background: '#fafcff',
      border: 'none',
    }),
    ...(isAbsent && {
      background: '#f4f4f4',
      border: '2px solid #dedede',
    }),
  })
);

export const CloseCard = styled(IconButton)(() => ({
  position: 'absolute',
  right: 4,
  top: 4,
  width: 36,
  height: 36,
  '&:hover': {
    color: '#00B39B',
  },
}));

export const SearchButton = styled(Button)(() => ({
  margin: '0 8px',
  backgroundColor: 'transparent',
  color: '#004D43',
  width: 'auto',
  height: '32px',
  fontWeight: '600',
  padding: '6px',
  '&:hover': {
    backgroundColor: '#004D431A',
    color: '#004D43',
  },
}));

export const HeaderContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const HeaderDescription = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  color: '#004E43',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  padding: '0 20px 10px',
  fontSize: '24px',
  fontWeight: '600',
}));

export const StyledImage = styled(CardMedia)(() => ({
  width: 42,
  height: 42,
}));

export const MenuButton = styled(ButtonBase)(() => ({
  border: '1px solid #D7D8DB',
  borderRadius: '4px',
  padding: '0 12px',
  marginRight: '20px',
  fontSize: '18px',
  fontWeight: '500',
  '&:hover': {
    color: '#333333',
    border: '1px solid #848791',
  },
  '&:disabled': {
    color: '#c2c4c8',
  },
  '& .MuiTouchRipple-root': {
    color: '#00B39B99',
  },
}));

export const DescriptionContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '8px 16px',
  justifyContent: 'flex-start',
}));

export const Bold = styled(Typography)(() => ({
  fontWeight: '500',
  marginLeft: '4px',
}));
