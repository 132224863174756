import {
  Box,
  BoxProps,
  Button,
  CardMedia,
  Grid,
  InputBase,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';

export const GridContainer = styled(Grid)(() => ({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    padding: '0 60px',
  },
}));

export const GridTitle = styled(Typography)(() => ({
  color: '#004D43',
  font: '500 21px Montserrat',
  marginBottom: 16,
}));

export const GridStyled = styled(Grid)(({ theme }) => ({
  height: 600,
  background: '#FFFCFE',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 4,
  padding: '30px 42px',
  justifyContent: 'center',
  overflowY: 'scroll',
  [theme.breakpoints.down('xl')]: {
    padding: '20px 30px',
  },
}));

export const GridButton = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  width: '100%',
  display: 'flex',
  gap: '10px',
  border: '2px solid #00B39B',
  borderRadius: '16px',
  color: '#00B39B',
  fontWeight: '600',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#00B39B',
  },
  '&:disabled': {
    color: '#00B39B',
  },
  '&:active': {
    backgroundColor: '#00B39B',
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  borderBottom: '1px solid',
  borderImage:
    'linear-gradient(to right, transparent 11%, #004D43 11%, #004D43 75%) 5',
  padding: '0 8px',
  marginBottom: '32px',
  width: '96%',
  [theme.breakpoints.up('xl')]: {
    borderImage:
      'linear-gradient(to right, transparent 9%, #004D43 9%, #004D43 75%) 5',
  },
}));

export const CardContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: 16,
  padding: 4,
  flexDirection: 'column',
  alignItems: 'center',
}));
interface CardStyledProps extends BoxProps {
  isAbsent?: boolean;
}

export const CardStyled = styled(Box)<CardStyledProps>(
  ({ theme, isAbsent }) => ({
    minHeight: '200px',
    height: 'auto',
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 10,
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    cursor: 'pointer',
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('xl')]: {
      '& .MuiTypography-root': {
        fontSize: '15px',
      },
    },
    ...(!isAbsent && {
      background: '#fafcff',
      border: 'none',
    }),
    ...(isAbsent && {
      background: '#f4f4f4',
      border: '2px solid #dedede',
    }),
  })
);

export const CompanyHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontSize: 20,
  fontWeight: 500,
  color: '#004D43',
  '& strong': {
    fontWeight: 500,
  },
}));

export const StyledImage = styled(CardMedia)(({ theme }) => ({
  width: 56,
  height: 56,
  marginBottom: '8px',
  marginRight: '6px',
  [theme.breakpoints.down('xl')]: {
    width: 48,
    height: 48,
  },
}));

export const SkeletonStyled = styled(Skeleton)(() => ({
  height: 193,
  width: '100%',
  background: '#fafcff',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 10,
}));
