/* eslint-disable react/require-default-props */
import { Box, CircularProgress } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';

import { CompanyEligibilityData } from '../../../api/theHive/workOrders/types';
import CustomMap from './CustomMap';
import { BoxContainer, LoadingBox } from './styles';

type LatLngLiteral = google.maps.LatLngLiteral;

interface CompaniesMapProps {
  companiesData: CompanyEligibilityData[];
  selectedCompany: CompanyEligibilityData | undefined;
  setSelectedCompany: (data: CompanyEligibilityData) => void;
  initialPinPlace: LatLngLiteral;
  setMapBounds: (value: google.maps.LatLngBounds | undefined) => void;
  loadingCompanies: boolean;
  osPinPlace?: LatLngLiteral;
}

export default function CompaniesMap({
  companiesData,
  selectedCompany,
  setSelectedCompany,
  initialPinPlace,
  setMapBounds,
  loadingCompanies,
  osPinPlace,
}: CompaniesMapProps): JSX.Element {
  return (
    <Box
      sx={{
        position: 'relative',
        'button.gm-ui-hover-effect': {
          visibility: 'hidden',
        },
        '.gm-style-iw-c': {
          minHeight: '40px',
          padding: '0 !important',
        },
        '.gm-style-iw-d': {
          textAlign: 'center',
          overflow: 'visible !important',
          minWidth: '34px',
          minHeight: '28px',
          marginTop: '-40px',
        },
      }}
    >
      {loadingCompanies && (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      )}
      <BoxContainer>
        <APIProvider
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
          libraries={['marker']}
        >
          <CustomMap
            initialPinPlace={initialPinPlace}
            markersData={companiesData}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            setMapBounds={setMapBounds}
            pinPlace={osPinPlace}
          />
        </APIProvider>
      </BoxContainer>
    </Box>
  );
}
