/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../../../api/enumerations';
import { createRequest } from '../../../../../../api/theHive/requests';
import { CompanyEligibilityData } from '../../../../../../api/theHive/workOrders/types';
import { PreffixNumericTextField } from '../../../../../../components/CustomInput';
import { WhiteCircularProgress } from '../../../../../../components/UI/Typography';
import {
  IconAssignmentMS,
  IconCloseMS,
  IconEastMS,
} from '../../../../../../constants/icons';
import { GlobalContext } from '../../../../../../context/global';
import { getMissingDays } from '../../../../../../helpers';
import useErrorMessage from '../../../../../../hooks/useErrorMessage';
import {
  AssingData,
  CloseButton,
  DialogTitle,
  OutlinedButton,
  StyledDialog,
  textFieldDefaultStyle,
  TopBox,
} from './styles';

interface AssignModalProps {
  open: boolean;
  handleClose: () => void;
  osId: number | undefined;
  osNumber: number;
  company: CompanyEligibilityData;
  modalCallback: () => void;
  disable: boolean;
}

export function AssignModal({
  open,
  handleClose,
  osId,
  osNumber,
  company,
  modalCallback,
  disable,
}: AssignModalProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [noticeId, setNoticeId] = useState(
    company?.public_notices[0].public_notice_id
  );
  const [price, setPrice] = useState<number>(0);
  const [daysToLimit, setDaysToLimit] = useState('0 dias');
  const [completeUntil, setCompleteUntil] = useState<Dayjs | null>(null);
  const today = dayjs();

  const { getErrorMessage } = useErrorMessage();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleRequest = async (): Promise<void> => {
    if (!osId || !noticeId || !company || !completeUntil) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    const data = {
      complete_until: dayjs(completeUntil).format('YYYY-MM-DD'),
      licensed_company_id: company.licensed_company_id,
      notice_id: noticeId,
      price,
      work_order_id: osId,
    };

    try {
      const response = await createRequest(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('OS atribuida à empresa credenciada!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      modalCallback();
      handleClose();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (completeUntil) {
      setDaysToLimit(getMissingDays(today, completeUntil));
    }
  }, [completeUntil]);

  return (
    <>
      <OutlinedButton onClick={handleClose} disabled={disable}>
        atribuir OS ao credenciado
      </OutlinedButton>
      <StyledDialog open={open} onClose={handleClose}>
        <>
          <TopBox>
            <DialogTitle>
              {IconAssignmentMS}
              Atribuir
            </DialogTitle>
            <CloseButton onClick={handleClose} disableTouchRipple>
              {IconCloseMS}
            </CloseButton>
          </TopBox>
          <Grid container spacing={3}>
            <Grid item xs={5.5}>
              <AssingData>
                <span>OS: {osNumber}</span>
              </AssingData>
            </Grid>
            <Grid
              item
              xs={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#00B39B"
            >
              {IconEastMS}
            </Grid>
            <Grid item xs={5.5}>
              <AssingData>
                <span>{company.name}</span>
              </AssingData>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="select-notice"
                select
                label="edital"
                color="secondary"
                value={noticeId}
                onChange={(e) => {
                  setNoticeId(Number(e.target.value));
                }}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': { borderRadius: '16px' },
                }}
              >
                {company.public_notices.map((option) => (
                  <MenuItem
                    key={option.public_notice_id}
                    value={option.public_notice_id}
                    sx={{
                      '&:hover': { backgroundColor: '#00B39B66' },
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <PreffixNumericTextField
                id="price"
                label="valor combinado"
                prefix="R$ "
                decimalSeparator=","
                decimalScale={2}
                maxLength={18}
                value={price}
                setValue={setPrice}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="data limite"
                  value={completeUntil}
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      sx: textFieldDefaultStyle,
                    },
                  }}
                  onChange={(e) => setCompleteUntil(e)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="dias"
                disabled
                color="secondary"
                value={daysToLimit}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': { borderRadius: '16px' },
                }}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="laudo"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="vistoria"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" mt="40px">
              <Button
                sx={{ width: '60%' }}
                onClick={handleRequest}
                disabled={loading}
              >
                {loading ? <WhiteCircularProgress size={22} /> : 'confirmar'}
              </Button>
            </Grid>
          </Grid>
        </>
      </StyledDialog>
    </>
  );
}
