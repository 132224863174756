/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
  getHiveWorkOrders,
  getWorkOrderEligibility,
} from '../../../../../api/theHive/workOrders';
import { CompanyEligibilityData } from '../../../../../api/theHive/workOrders/types';
import { WorkOrderData } from '../../../../../api/workOrders/types';
import CompaniesMap from '../../../../../components/Maps/CompaniesMap';
import { IconCloseMS, IconSearchMS } from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import {
  formatCep,
  formatCnpjCpf,
  formatDate,
  formatPhoneNumber,
} from '../../../../../helpers';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import logoRealPrice from '../../icons/realprice.svg';
import { AssignModal } from './AssignModal';
import {
  Bold,
  CardStyled,
  CloseCard,
  Container,
  DescriptionContainer,
  HeaderContainer,
  HeaderDescription,
  MenuButton,
  SkeletonStyled,
  StyledDialog,
  StyledImage,
  StyledInputBase,
} from './styles';

export function Map(): JSX.Element {
  const [loadingOs, setLoadingOs] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [reloadData, setReloadData] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const osPerPage = 20;

  const [search, setSearch] = useState('');
  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>([]);
  const [selectedOS, setSelectedOS] = useState<WorkOrderData | undefined>();
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds>();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [licensedCompanies, setLicensedCompanies] = useState<
    CompanyEligibilityData[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyEligibilityData | undefined
  >();
  const [anchorNoticeMenu, setAnchorNoticeMenu] = useState<null | HTMLElement>(
    null
  );
  const isNoticeMenuOpen = Boolean(anchorNoticeMenu);

  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const inputRef = useRef<HTMLInputElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const open = Boolean(anchorEl);
  const handleClick = (): void => setAnchorEl(inputRef.current);
  const handleClose = (): void => setAnchorEl(null);

  const closeNoticeMenu = (): void => setAnchorNoticeMenu(null);
  const openNoticeMenu = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setAnchorNoticeMenu(e.currentTarget);

  const searchHiveWorkOrders = useCallback(async () => {
    if (page === lastCalledPage) return;
    setLoadingOs(true);

    try {
      const response = await getHiveWorkOrders(search, page, osPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && page === 1) {
        setTotalPages(response.detail.total_pages);
      }

      setSelectedCompany(undefined);

      if (page === 1) {
        setWorkOrders(response.data);
      } else {
        setWorkOrders([...workOrders, ...response.data]);
        setLastCalledPage(page);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingOs(false);
    }
  }, [search, page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLastCalledPage(0);
      setPage(1);
      setReloadData(!reloadData);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      searchHiveWorkOrders();
    }
  }, [page]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    searchHiveWorkOrders();
  }, [reloadData]);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loadingOs) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(page + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  const handleWorkOrderEligibility = async (): Promise<void> => {
    if (!selectedOS) return;
    setLoadingCompanies(true);

    const coord = mapBounds
      ? {
          low_latitude: mapBounds.toJSON().west.toString(),
          low_longitude: mapBounds.toJSON().north.toString(),
          high_latitude: mapBounds.toJSON().east.toString(),
          high_longitude: mapBounds.toJSON().south.toString(),
        }
      : null;

    try {
      const response = await getWorkOrderEligibility(selectedOS.id, coord);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLicensedCompanies(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingCompanies(false);
    }
  };

  useEffect(() => {
    if (selectedOS) handleWorkOrderEligibility();
  }, [selectedOS]);

  useEffect(() => {
    if (selectedOS) handleWorkOrderEligibility();
  }, [mapBounds]);

  return (
    <Container container spacing={2}>
      <Grid item xs={5}>
        <StyledInputBase
          ref={inputRef}
          placeholder="buscar OS"
          inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
          type="search"
          value={search}
          startAdornment={
            <InputAdornment position="start" sx={{ color: '#004D43' }}>
              {IconSearchMS}
            </InputAdornment>
          }
          onChange={(e) => {
            setSearch(e.target.value);
            handleClick();
          }}
          onClick={handleClick}
        />
        <StyledDialog
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handleClose}
          aria-labelledby="search-popover"
          disableAutoFocus
          disableEnforceFocus
          disableScrollLock
        >
          {workOrders.length > 0
            ? workOrders.map((workOrder) => (
                <CardStyled
                  key={workOrder.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    setSelectedOS(workOrder);
                  }}
                  className={workOrder.id === selectedOS?.id ? 'selected' : ''}
                >
                  <Typography>
                    <strong>número OS: </strong>
                    {workOrder.reference_number}
                  </Typography>
                  <Typography>
                    <strong>cidade:</strong> {workOrder.city}
                  </Typography>
                  <Typography>
                    <strong>localização:</strong> {workOrder.street} -{' '}
                    {workOrder.number}
                  </Typography>
                </CardStyled>
              ))
            : 'OS não encontrada'}
          {loadingOs ? (
            [1, 2, 3].map((item) => (
              <SkeletonStyled
                key={item}
                variant="rectangular"
                animation="pulse"
              />
            ))
          ) : (
            <Box ref={lastElementRef} />
          )}
        </StyledDialog>
      </Grid>
      <Grid item xs={7} />
      <Grid item xs={5}>
        {selectedOS && (
          <CardStyled sx={{ position: 'relative', cursor: 'auto' }}>
            <CloseCard
              onClick={() => {
                setSelectedOS(undefined);
                setSelectedCompany(undefined);
                setLicensedCompanies([]);
              }}
            >
              {IconCloseMS}
            </CloseCard>
            <Typography>
              <strong>número OS: </strong>
              {selectedOS.reference_number}
            </Typography>
            <Typography>
              <strong>cidade:</strong> {selectedOS.city}
            </Typography>
            <Typography>
              <strong>localização:</strong> {selectedOS.street} -{' '}
              {selectedOS.number}
            </Typography>
          </CardStyled>
        )}
        {selectedOS && selectedCompany && (
          <AssignModal
            disable={
              selectedCompany.is_absent_in_period || !selectedCompany.is_active
            }
            osId={selectedOS.id}
            osNumber={selectedOS.reference_number}
            company={selectedCompany}
            open={openAssignModal}
            handleClose={() => setOpenAssignModal(!openAssignModal)}
            modalCallback={() => {
              setSearch('');
              setSelectedOS(undefined);
              setWorkOrders([]);
              setSelectedCompany(undefined);
              setLicensedCompanies([]);
            }}
          />
        )}
      </Grid>
      <Grid item xs={7}>
        {selectedCompany && (
          <CardStyled
            sx={{ position: 'relative', cursor: 'auto' }}
            isAbsent={
              selectedCompany.is_absent_in_period || !selectedCompany.is_active
            }
          >
            <CloseCard onClick={() => setSelectedCompany(undefined)}>
              {IconCloseMS}
            </CloseCard>
            <HeaderContainer>
              <HeaderDescription>
                <StyledImage
                  image={logoRealPrice}
                  title={selectedCompany.name}
                />
                {selectedCompany.name}
              </HeaderDescription>
              <Box>
                <MenuButton
                  onClick={openNoticeMenu}
                  disabled={selectedCompany.public_notices.length < 1}
                >
                  editais
                </MenuButton>
                <Menu
                  anchorEl={anchorNoticeMenu}
                  open={isNoticeMenuOpen}
                  onClose={closeNoticeMenu}
                >
                  {selectedCompany.public_notices.map((notice) => (
                    <MenuItem key={notice.public_notice_id}>
                      {notice.name}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </HeaderContainer>
            <Divider sx={{ width: '100%' }} />
            <DescriptionContainer mt="16px">
              <Grid container spacing={2}>
                <Grid display="flex" item xs={5}>
                  Status:
                  <Bold
                    sx={{
                      color: selectedCompany.is_active ? '#00B39B' : '#a9a9a9',
                    }}
                  >
                    {selectedCompany.is_active ? ' ativa' : ' inativa'}
                  </Bold>
                </Grid>
                <Grid display="flex" item xs={7}>
                  Distância:{' '}
                  <Bold>{selectedCompany.distance.toFixed(2)} km</Bold>
                </Grid>
                <Grid display="flex" item xs={5}>
                  {selectedCompany.document_type?.toUpperCase() || 'CPF'}:
                  <Bold>{formatCnpjCpf(selectedCompany.document)}</Bold>
                </Grid>
                <Grid display="flex" item xs={7}>
                  Data do credenciamento:{' '}
                  <Bold>{formatDate(selectedCompany.licensed_date)}</Bold>
                </Grid>
                <Grid display="flex" item xs={5}>
                  Telefone:{' '}
                  <Bold>{formatPhoneNumber(selectedCompany.phone_number)}</Bold>
                </Grid>
                <Grid display="flex" item xs={7}>
                  Email: <Bold>{selectedCompany.email}</Bold>
                </Grid>
                <Grid display="flex" item xs={5}>
                  CEP: <Bold>{formatCep(selectedCompany.zipcode)}</Bold>
                </Grid>
                <Grid display="flex" item xs={7}>
                  Cidade:{' '}
                  <Bold>{`${selectedCompany.city} - ${selectedCompany.uf} `}</Bold>
                </Grid>
                <Grid display="flex" item xs={12}>
                  Logradouro:{' '}
                  <Bold>
                    {`${selectedCompany.address}, ${selectedCompany.address_number} - ${selectedCompany.address_district}`}
                  </Bold>
                </Grid>
                {selectedCompany.is_active && (
                  <Grid item xs={12}>
                    {selectedCompany.is_absent_in_period ? (
                      <Box
                        sx={{ fontSize: '15px !important', fontWeight: '500' }}
                      >
                        ausente no momento
                      </Box>
                    ) : (
                      !selectedCompany.coverage && (
                        <Box
                          sx={{
                            fontSize: '14px !important',
                            fontWeight: '500',
                            color: '#ff6a06',
                          }}
                        >
                          região fora da área de cobertura do credenciado, mas é
                          possível atribuir a OS
                        </Box>
                      )
                    )}
                  </Grid>
                )}
              </Grid>
            </DescriptionContainer>
          </CardStyled>
        )}
      </Grid>
      <Grid item xs={12}>
        <CompaniesMap
          companiesData={licensedCompanies}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          setMapBounds={setMapBounds}
          loadingCompanies={loadingCompanies}
          osPinPlace={
            selectedOS && {
              lat: Number(selectedOS.latitude),
              lng: Number(selectedOS.longitude),
            }
          }
          initialPinPlace={{
            lat: -23.56162,
            lng: -46.65591,
          }}
        />
      </Grid>
    </Container>
  );
}
