/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, TableContainer } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../api/enumerations';
import { getLastMessages } from '../../api/theHive/messages';
import { LastMessagesData } from '../../api/theHive/messages/types';
import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { IconNotificationsMS } from '../../constants/icons';
import { Constants } from '../../constants/notifications';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import { NotificationsTable } from './Table';

export function Notifications(): JSX.Element {
  const [lastMessages, setLastMessages] = useState<LastMessagesData[]>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const rowsPerPage = 10;

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getNotifications = useCallback(async () => {
    setLoading(true);
    try {
      const lastMessagesResponse = await getLastMessages(page + 1, rowsPerPage);
      if (lastMessagesResponse.detail.description) {
        throw new Error(lastMessagesResponse.detail.description);
      }

      if (lastMessagesResponse.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (lastMessagesResponse.detail.total_records) {
        setTotalRecords(lastMessagesResponse.detail.total_records);
      }

      setLastMessages(lastMessagesResponse.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>
          {IconNotificationsMS} {Constants.notifications}
        </TitleTypography>
      </TitleBox>
      <TableContainer>
        {loading ? (
          <LinearProgress />
        ) : lastMessages && lastMessages.length > 0 ? (
          <NotificationsTable
            tableData={lastMessages}
            page={page}
            setPage={setPage}
            totalPages={totalRecords}
            rowsPerPage={rowsPerPage}
          />
        ) : (
          Constants.noNotifications
        )}
      </TableContainer>
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}
