/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Rating,
  Typography,
} from '@mui/material';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { convertClientKind, StatusCode } from '../../../../api/enumerations';
import { createAutoRequest } from '../../../../api/theHive/requests';
import {
  getHiveWorkOrders,
  getWorkOrderEligibility,
} from '../../../../api/theHive/workOrders';
import { CompanyEligibilityData } from '../../../../api/theHive/workOrders/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import {
  IconKeyboardDoubleArrowRightMS,
  IconSearchMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { LoadingSpinner } from '../Notices/components/LoadingSpinner';
import { AssignModal } from './AssignModal';
import {
  CardContainer,
  CardStyled,
  CompanyHeader,
  GridButton,
  GridContainer,
  GridStyled,
  GridTitle,
  OutlinedButton,
  SkeletonStyled,
  StyledImage,
  StyledInputBase,
} from './styles';

export function Requests(): JSX.Element {
  const [loadingOs, setLoadingOs] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const osPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);

  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>([]);
  const [selectedOS, setSelectedOS] = useState<number | undefined>();
  const [selectedOSNumber, setSelectedOSNumber] = useState<string>('');
  const [search, setSearch] = useState('');

  const [licensedCompanies, setLicensedCompanies] = useState<
    CompanyEligibilityData[]
  >([]);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyEligibilityData>();

  const observer = useRef<IntersectionObserver | null>(null);

  const { getErrorMessage } = useErrorMessage();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) return;
    setLoadingOs(true);

    try {
      const response = await getHiveWorkOrders(search, page, osPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && page === 1) {
        setTotalPages(response.detail.total_pages);
      }

      if (page === 1) {
        setWorkOrders(response.data);
      } else {
        setWorkOrders([...workOrders, ...response.data]);
        setLastCalledPage(page);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingOs(false);
    }
  }, [search, page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLastCalledPage(0);
      setPage(1);
      setReloadData(!reloadData);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      getDataCallback();
    }
  }, [page]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    getDataCallback();
  }, [reloadData]);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loadingOs) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(page + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  const handleWorkOrderEligibility = async (): Promise<void> => {
    if (!selectedOS) return;
    try {
      const response = await getWorkOrderEligibility(selectedOS);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLicensedCompanies(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingCompany(false);
    }
  };

  useEffect(() => {
    if (selectedOS) {
      setLoadingCompany(true);
      handleWorkOrderEligibility();
    } else {
      setLicensedCompanies([]);
      setSelectedCompany(undefined);
    }
  }, [selectedOS]);

  useEffect(() => {
    setLicensedCompanies([]);
    setSelectedCompany(undefined);
  }, [isLoading]);

  const handleAutoAssign = useCallback(async () => {
    const osIds = workOrders.map((os) => os.id);

    const data = {
      work_orders_ids: osIds,
    };

    try {
      const response = await createAutoRequest(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSnackbarMessage('Ordens de serviço atribuídas com sucesso!');
      setOpenSnackbar(true);
      if (search.length > 0) {
        setSearch('');
      } else {
        setLastCalledPage(0);
        setPage(1);
        setReloadData(!reloadData);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [workOrders]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <GridContainer container spacing={4}>
      <Grid item xs={5}>
        <GridTitle>Lista de OS</GridTitle>
        <GridStyled item>
          <StyledInputBase
            placeholder="buscar informação da OS"
            inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
            type="search"
            value={search}
            startAdornment={
              <InputAdornment position="start" sx={{ color: '#004D43' }}>
                {IconSearchMS}
              </InputAdornment>
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setSearch(e.target.value);
              }
              if (e.target.value.length === 0) {
                setSearch('');
              }
            }}
            onFocus={(e) => {
              e.target.value = '';
              setSearch('');
            }}
          />
          <CardContainer>
            <Button
              sx={{ borderRadius: '16px', mb: '20px' }}
              disabled={workOrders.length === 0}
              onClick={handleAutoAssign}
            >
              atribuir automaticamente
            </Button>
            {workOrders.length > 0 &&
              workOrders.map((workOrder) => (
                <CardStyled
                  key={workOrder.id}
                  onClick={() => {
                    if (selectedOS === workOrder.id) {
                      setSelectedOS(undefined);
                      setSelectedOSNumber('');
                    } else {
                      setSelectedOS(workOrder.id);
                      setSelectedOSNumber(`${workOrder.reference_number}`);
                    }
                  }}
                  className={workOrder.id === selectedOS ? 'selected' : ''}
                >
                  <Typography>
                    <strong>número OS:</strong> {workOrder.reference_number}
                  </Typography>
                  <Typography>
                    <strong>nome do cliente:</strong> {workOrder.client_name}
                  </Typography>
                  <Typography>
                    <strong>tipo do cliente:</strong>{' '}
                    {convertClientKind(workOrder.client_kind)}
                  </Typography>
                  <Typography>
                    <strong>cidade:</strong> {workOrder.city}
                  </Typography>
                  <Typography>
                    <strong>localização:</strong> {workOrder.street} -{' '}
                    {workOrder.number}
                  </Typography>
                </CardStyled>
              ))}
            {loadingOs ? (
              [1, 2, 3, 4, 5].map((item) => (
                <SkeletonStyled
                  key={item}
                  variant="rectangular"
                  animation="pulse"
                />
              ))
            ) : (
              <Box ref={lastElementRef} />
            )}
          </CardContainer>
        </GridStyled>
      </Grid>
      <GridButton item xs={2}>
        <OutlinedButton
          onClick={() => setOpenModal(!openModal)}
          disabled={!selectedOS || !selectedCompany}
        >
          atribuir {IconKeyboardDoubleArrowRightMS}
        </OutlinedButton>
      </GridButton>
      <Grid item xs={5}>
        <GridTitle>Credenciados</GridTitle>
        <GridStyled item>
          {!selectedOS ? (
            <Typography>selecione uma OS</Typography>
          ) : (
            <>
              <StyledInputBase
                placeholder="buscar por CNPJ"
                inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
                type="search"
                startAdornment={
                  <InputAdornment position="start" sx={{ color: '#004D43' }}>
                    {IconSearchMS}
                  </InputAdornment>
                }
              />
              <CardContainer>
                {licensedCompanies.length > 0 ? (
                  licensedCompanies.map((data) => (
                    <CardStyled
                      isAbsent={data.is_absent_in_period}
                      onClick={() => {
                        if (data.is_absent_in_period || !data.is_active) return;
                        if (
                          selectedCompany?.licensed_company_id ===
                          data.licensed_company_id
                        ) {
                          setSelectedCompany(undefined);
                        } else {
                          setSelectedCompany(data);
                        }
                      }}
                      className={
                        selectedCompany?.licensed_company_id ===
                        data.licensed_company_id
                          ? 'selected'
                          : ''
                      }
                    >
                      <CompanyHeader>
                        <StyledImage image={data.logo_url} title="empresa" />
                        {data.name}
                      </CompanyHeader>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 8,
                        }}
                      >
                        <Typography>
                          <strong>CNPJ:</strong> {data.document}
                        </Typography>
                        <Typography>
                          <strong>Cidade:</strong> {data.city}
                        </Typography>
                        <Typography>
                          <strong>Status:</strong>
                          <strong
                            style={{
                              color: data.is_active ? '#00B39B' : '#a9a9a9',
                            }}
                          >
                            {data.is_active ? ' ativa' : ' inativa'}
                          </strong>
                        </Typography>
                        <Typography>
                          <strong>Distância:</strong> {data.distance.toFixed(2)}{' '}
                          km
                        </Typography>
                        <Rating value={data.rating} />
                        {data.is_active && (
                          <Box>
                            {data.is_absent_in_period ? (
                              <Typography
                                fontWeight={500}
                                sx={{ fontSize: '14px !important' }}
                              >
                                ausente no momento
                              </Typography>
                            ) : (
                              !data.coverage && (
                                <Typography
                                  fontWeight={500}
                                  sx={{
                                    fontSize: '14px !important',
                                    color: '#ff6a06',
                                  }}
                                >
                                  região fora da área de cobertura do
                                  credenciado, mas é possível atribuir a OS
                                </Typography>
                              )
                            )}
                          </Box>
                        )}
                      </Box>
                    </CardStyled>
                  ))
                ) : (
                  <Typography>
                    {loadingCompany
                      ? ''
                      : 'Nenhum edital ativo cobre a tipologia desta Ordem de Serviço'}
                  </Typography>
                )}
                {loadingCompany &&
                  [1, 2, 3, 4, 5].map((item) => (
                    <SkeletonStyled
                      key={item}
                      variant="rectangular"
                      animation="pulse"
                    />
                  ))}
              </CardContainer>
            </>
          )}
        </GridStyled>
      </Grid>
      {selectedCompany && (
        <AssignModal
          osId={selectedOS}
          osNumber={selectedOSNumber}
          company={selectedCompany}
          open={openModal}
          handleClose={() => setOpenModal(!openModal)}
          setParentLoading={setIsLoading}
          modalCallback={() => {
            if (search.length > 0) {
              setSearch('');
            } else {
              setLastCalledPage(0);
              setPage(1);
              setReloadData(!reloadData);
            }
          }}
        />
      )}
    </GridContainer>
  );
}
