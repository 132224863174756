import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RelativeBox = styled(Box)(() => ({
  position: 'relative',
  minWidth: '160px',
  width: '250px',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  margin: '-8px -8px 0',
  backgroundColor: '#414141',
  padding: '4px',
  color: '#FFFFFF',
  zIndex: '20',
  '& span': {
    fontWeight: '600',
  },
  '&:hover': {
    padding: '5px',
    color: '#FFFFFF',
    backgroundColor: '#00B39B',
  },
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  width: '100%',
  height: '115px',
  borderRadius: '12px',
  backgroundSize: 'cover',
  filter: 'brightness(70%)',
  cursor: 'pointer',
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const StyledCardText = styled(Typography)(() => ({
  width: '100%',
  height: '100%',
  top: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  fontSize: '24px',
  color: '#FAFCFF',
  fontWeight: '600',
  cursor: 'pointer',
  padding: '0 4px',
  textAlign: 'center',
  '&:hover': {
    fontSize: '26px',
  },
}));

export const PicturesCount = styled(Box)(() => ({
  margin: '-44px 8px 0',
  zIndex: '10',
  backgroundColor: '#1A1A1A',
  borderRadius: '50%',
  padding: '4px',
  color: '#EDEFF2',
  width: '36px',
  height: '36px',
  display: 'flex',
  fontSize: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid #707070',
  fontWeight: '600',
  cursor: 'default',
}));
